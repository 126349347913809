import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./dropdownmenu.less";

import down from "../../assets/down.png";

const DropDownMenu = (props) => {
  const { title, ment } = props;
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    // document.onclick = () => {
    //   setIsShow(false);
    // };
  }, []);
  // const onNoGuan = (e) => {
  //   window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
  // };
  return (
    <div
      className="drop_down_menu"
      onMouseEnter={() => setIsShow(true)}
      onMouseLeave={() => setIsShow(false)}
    >
      <div
        className={
          isShow
            ? "drop_down_menu_title drop_down_menu_title_active"
            : "drop_down_menu_title"
        }
      >
        <span className="title">{title}</span>
        <img src={down} alt="" />
      </div>

      <div
        className={
          isShow
            ? "drop_down_menu_box drop_down_menu_box_active"
            : "drop_down_menu_box "
        }
      >
        {ment.map((item, i) => {
          return (
            <a
              href={item.path}
              key={i}
              className="drop_down_menu_item"
              activeClassName="drop_down_menu_item_active"
            >
              {item.title}
            </a>
          );
        })}
      </div>
    </div>
  );
};
export default DropDownMenu;
