const menus = {
  // 菜单相关路由
  menus: [
    {
      key: "/",
      component: "Home",
    },
  ],
  lists: [],
};

export default menus;
