import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
const Frame = (props) => {
  useEffect(() => {}, []);
  return (
    <div>
      <div>{props.children}</div>
    </div>
  );
};
export default withRouter(Frame);
