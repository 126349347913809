/*
 * @Author: your name
 * @Date: 2023-10-11 21:06:32
 * @LastEditTime: 2023-10-11 21:13:01
 * @LastEditors: your name
 * @Description:
 * @FilePath: \W3WRLLET-web\src\App.jsx
 * 可以输入预定的版权声明、个性签名、空行等
 */
import "./App.less";
import Router from "./router";
import Frame from "./components/Frame/index";
import { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import "./i18n";

function App() {
  useEffect(() => {
    getRemsize();
    window.addEventListener("resize", getRemsize);
    return () => window.removeEventListener("resize", getRemsize);
  }, []);
  const getRemsize = () => {
    const whdef = 100 / 1920;
    let ww = window.innerWidth;
    if (ww >= 1920) {
      ww = 1920;
    } else if (ww <= 768) {
      ww = 768;
    }
    const rem = ww * whdef;
    document.querySelectorAll("html")[0].style.fontSize = rem + "px";
  };
  return (
    <BrowserRouter>
      <Switch>
        <Frame>
          <Router />
        </Frame>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
