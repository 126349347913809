import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./header.less";
import DropDownMenu from "../DropDownMenu";
import { Drawer } from "antd";

import logo from "../../assets/logo.png";
import zh from "../../assets/zh.png";
import en from "../../assets/en.png";
import jp from "../../assets/jp.png";
import ko from "../../assets/ko.png";
import menu from "../../assets/menu.png";
import logom from "../../assets/logom.png";
import close from "../../assets/close.png";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [ln, setLn] = useState(localStorage.getItem("lang") || "en");
  const [open, setOpen] = useState(false);

  const productMent = [
    {
      title: t("wallet"),
      path: "/",
    },
    {
      title: "W3swap",
      path: "https://w3swap.link/",
    },
    {
      title: "W3bridge",
      path: "https://www.w3bridge.net/",
    },
    {
      title: "W3bank",
      path: "https://w3bank.finance/",
    },
  ];
  const ecologicalMent = [
    {
      title: t("Submit Dapp"),
      path: "https://forms.gle/1caSePYgAAPdTx8s9",
    },
    {
      title: t("Submit Tokens"),
      path: "https://forms.gle/FHUpFjGDckmZejSw9",
    },
  ];
  const lang = [
    {
      key: "zh",
      img: zh,
    },
    {
      key: "en",
      img: en,
    },
    {
      key: "jp",
      img: jp,
    },
    {
      key: "ko",
      img: ko,
    },
  ];

  const setLang = (key) => {
    i18n.changeLanguage(key);
    localStorage.setItem("lang", key);
    setLn(key);
  };

  useEffect(() => {}, []);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="header">
      <div className="header_con">
        <div className="header_left">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>
        <div className="header_right">
          <div className="menu">
            <div className="menu_item">
              <DropDownMenu title={t("Product portfolio")} ment={productMent} />
            </div>
            <div className="menu_item">
              <DropDownMenu
                title={t("Ecological cooperation")}
                ment={ecologicalMent}
              />
            </div>
          </div>
          <div className="lang">
            {lang.map((item, i) => {
              return (
                <div
                  className={ln === item.key ? `quan ${item.key}_quan` : "quan"}
                  onClick={() => setLang(item.key)}
                  key={i}
                >
                  <img src={item.img} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="menu_icon" onClick={showDrawer}>
        <img src={menu} alt="" />
      </div>

      <Drawer
        placement="right"
        onClose={onClose}
        open={open}
        width="100%"
        closable={false}
        className="menu_drawer"
        mask={false}
        autoFocus={false}
      >
        <div className="top">
          <img src={logom} alt="" className="logo" />
          <img src={close} alt="" className="close" onClick={onClose} />
        </div>
        <div className="productment">
          <div className="menu">
            <h2>{t("Product portfolio")}</h2>
            {productMent.map((item, i) => {
              return (
                <a href={item.path} key={i}>
                  {item.title}
                </a>
              );
            })}
          </div>
          <div className="menu">
            <h2>{t("Ecological cooperation")}</h2>
            {ecologicalMent.map((item, i) => {
              return (
                <a href={item.path} key={i}>
                  {item.title}
                </a>
              );
            })}
          </div>
          <div className="lang">
            {lang.map((item, i) => {
              return (
                <div
                  className={ln === item.key ? `quan ${item.key}_quan` : "quan"}
                  onClick={() => setLang(item.key)}
                  key={i}
                >
                  <img src={item.img} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
    </div>
  );
};
export default Header;
