import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./footer.less";

import flogo from "../../assets/flogo.png";
import logo from "../../assets/logo.png";
import x from "../../assets/x.png";
import telegram from "../../assets/telegram.png";
const Footer = (props) => {
  const { t } = useTranslation();
  useEffect(() => {}, []);
  return (
    <div className="footer">
      <div className="footer_top">
        <div className="footer_left">
          <a href="/">
            <img src={flogo} alt="" />
          </a>
        </div>
        <div className="footer_right">
          <div className="navigation">
            <h2>{t("Learn more")}</h2>
            <a href="https://w3swap.pro/W3WalletWhitepaper_EN.pdf">{t("White paper")}</a>
           {/* <a href="/">{t("developer documentation")}</a>
            <a href="/">{t("download")}</a>
            <a href="/">{t("audit report")}</a>*/}
          </div>
          <div className="navigation">
            <h2>{t("ecology")}</h2>
            <a href="https://pego.network/#/">PEGO Network</a>
            <a href="https://w3swap.link/">W3Swap</a>
            <a href="https://www.w3bridge.net/">W3Bridge</a>
            <a href="https://w3bank.finance/">W3Bank</a>
          </div>
          <div className="navigation">
            <h2>{t("Join us")}</h2>
            <div className="img_href">
              <a href="https://twitter.com/w3_foundation">
                <img src={x} alt="" />
              </a>
              <a href="https://t.me/W3_Global">
                <img src={telegram} alt="" />
              </a>
            </div>
          </div>
          <div className="navigation">
            <h2>NEWS</h2>
{/*
            <a href="/">{t("Ecological entry application")}</a>
*/}
            <a href="https://forms.gle/GPhVbJZ77qKXN5WW9">{t("Application for entry into Duo Dig Long Chang")}</a>
            <a href="https://forms.gle/1caSePYgAAPdTx8s9">{t("Dapp inclusion application")}</a>
            <a href="https://forms.gle/FHUpFjGDckmZejSw9">{t("Token information inclusion application")}</a>
          </div>
        </div>
        <div className="footer_right_mobile">
          <div className="navigation">
            <div className="navigation_left">
              <h2>{t("Learn more")}</h2>
            </div>
            <div className="navigation_xian"></div>
            <div className="navigation_right">
              <a href="https://w3swap.pro/W3WalletWhitepaper_EN.pdf">{t("White paper")}</a>
             {/* <a href="/">{t("developer documentation")}</a>
              <a href="/">{t("audit report")}</a>
              <a href="/">{t("download")}</a>*/}
            </div>
          </div>
          <div className="navigation">
            <div className="navigation_left">
              <h2>{t("ecology")}</h2>
            </div>
            <div className="navigation_xian"></div>
            <div className="navigation_right">
              <a href="https://pego.network/#/">PEGO Network</a>
              <a href="https://w3swap.link/">W3Swap</a>
              <a href="https://www.w3bridge.net/">W3Bridge</a>
            </div>
          </div>
          <div className="navigation">
            <div className="navigation_left">
              <h2>NEWS</h2>
            </div>
            <div className="navigation_xian"></div>
            <div className="navigation_right">
{/*
              <a href="/">{t("Ecological entry application")}</a>
*/}
              <a href="https://forms.gle/GPhVbJZ77qKXN5WW9">
                {t("Application for entry into Duo Dig Long Chang")}
              </a>
              <a href="https://forms.gle/1caSePYgAAPdTx8s9">{t("Dapp inclusion application")}</a>
              <a href="https://forms.gle/FHUpFjGDckmZejSw9">{t("Token information inclusion application")}</a>
            </div>
          </div>
          <div className="navigation">
            <div className="navigation_left">
              <h2>{t("Join us")}</h2>
            </div>
            <div className="navigation_xian"></div>
            <div className="navigation_right">
              <a href="https://twitter.com/w3_foundation">
                <img src={x} alt="" />
              </a>
              <a href="https://t.me/W3_Global">
                <img src={telegram} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bom">
        <a href="/">
          <img src={logo} alt="" />
        </a>
      </div>
    </div>
  );
};
export default Footer;
