import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation, Trans } from "react-i18next";

import "./home.less";

import bannertitle from "../../assets/bannertitle.png";
import banner from "../../assets/banner.png";
import android from "../../assets/android.png";
import abilityone from "../../assets/abilityone.png";
import abilitytwo from "../../assets/abilitytwo.png";
import abilitytherr from "../../assets/abilitytherr.png";
import logoblg from "../../assets/logoblg.png";
import mobilefind from "../../assets/mobilefind.png";

const Home = (props) => {
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <div className="home">
      <Header />
      <div className="banner">
        <div className="banner_left">
          <div className="banner_title">
            {/* <img src={bannertitle} alt="" /> */}
            <div className="banner_title_left">
              <div className="banner_title_left_t">Web3</div>
              <div className="banner_title_hei"></div>
            </div>
            <div className="banner_title_right">{t("at Your Fingertips")}</div>
          </div>
          <p className="banner_text">{t("Banner Text")}</p>
          <a href="https://w3wallet.link/download/W3Wallet.apk" className="banner_download">
            <span>{t("DOWNLOAD NOW")}</span>
            <img src={android} alt="" />
          </a>
        </div>
        <div className="banner_right"></div>
      </div>

      <div className="ability">
        <div className="ability_box">
          <div className="ability_box_con">
            <div className="ability_title">{t("ability title one")}</div>
            <div className="ability_xian"></div>
            <p className="ability_text">{t("ability text one")}</p>
          </div>
          <img src={abilityone} alt="" />
        </div>
        <div className="ability_box">
          <div className="ability_box_con">
            <div className="ability_title">{t("ability title two")}</div>
            <div className="ability_xian"></div>
            <p className="ability_text">{t("ability text two")}</p>
          </div>
          <img src={abilitytwo} alt="" />
        </div>
        <div className="ability_box">
          <div className="ability_box_con">
            <div className="ability_title">{t("ability title therr")}</div>
            <div className="ability_xian"></div>
            <p className="ability_text">{t("ability text therr")}</p>
          </div>
          <img src={abilitytherr} alt="" />
        </div>
      </div>
      <div className="find">
        <div className="find_con">
          <div className="find_con_left">
            <div className="find_con_title">
              <Trans
                i18nKey={"find con title"}
                components={{ span: <span /> }}
              />
            </div>
            <p className="find_con_text">{t("find con text")}</p>
          </div>
          <img src={logoblg} alt="" className="pc_finglogo" />
          <img src={mobilefind} alt="" className="mb_finglogo" />
        </div>
      </div>
      <div className="download_con">
        <h2 className="download_title">{t("start using")}</h2>
        <a href="https://w3wallet.link/download/W3Wallet.apk" className="download_btn">
          {t("Download W3Wallet now")}
        </a>
        <div className="download_instructions">
          <img src={android} alt="" />
          <span>{t("Download instructions")}</span>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Home;
